.tabs-section-acc {
  height: 100% !important;
  &::-webkit-scrollbar {
    width: 0;
  }
  @media (max-width: 700px) {
    display: none !important;
  }
}

.tabs-acc {
  display: flex;
  width: 30%;
}

.tab-button-acc {
  flex: 1;
  border: none;
  cursor: pointer;
  border-radius: 0px;
}

.tab-content-acc {
  display: none;

  &.tab-content-active-acc {
    display: block;
    position: relative;
    height: 86%;
  }
}
.bottom-table-bstorm{
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  .overflow-y-table-body{
    position: absolute;
    height: calc(100% - 85px);
    overflow-y: overlay;
    bottom: 20px;
    top: 85px;
    width: 100%;
  }
  .bstorm-head,.bstorm-body-tr{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    padding: 0 1rem !important;
    h3,p{
      font-size: 12px;
      height: 100%;
      width: 100%;
      display: flex;
    justify-content: flex-start;
    align-items: center;
    }
    p:nth-last-child(1){
      width: calc(100% + .7rem);
      margin-right: -.7rem !important;
    }
  }
}

// POSITION SECTION............................................................................................
.pos-section {
  padding: 8px;
}

.open-positions-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    font-size: 10px;
    border: 0px;
  }
}

.open-positions-table th,
.open-positions-table td {
  font-weight: normal;
  text-align: center;
}

.open-positions-table th {
  padding: 3px;
}

.open-positions-table td {
  border-bottom: 1px solid;
  font-size: 10px;
  padding: 2px;
}

// HISTORY SECTION............................................................................................
.history-section {
  padding: 8px;
}

.history-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    font-size: 10px;
    border: 0px;
  }
}

.history-table th,
.history-table td {
  font-weight: normal;
  text-align: center;
}

.history-table th {
  padding: 3px;
}

.history-table td {
  border-bottom: 1px solid;
  font-size: 10px;
  padding: 2px;
}
