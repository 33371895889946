.metrics-panel {

    padding: 20px;
    margin: 20px;

    .metrics-list {
        list-style-type: none;
        padding: 0;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            strong {
                margin-right: 8px;
                font-size: 9.5px;
            }
        }
    }
}