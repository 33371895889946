.mode-tabs {
	margin-top: 20px;
	width: 100%;
	text-align: center;		
}

.mode-tab-button {
	width: 40%;
	margin: 1px;
}

.order-panel {
	height: 70vh;
	transition: height 0.5s ease;
}

.order-panel-symbol {
	padding: 10px;
	font-weight: bold;
}

.order-tabs {
	display: flex;
}

.tab-button {
	flex: 1;
	border: none;
	cursor: pointer;
	border-radius: 0px;	
}

.order-section {
	display: none;
	padding: 20px;
	margin-top: -1px;
	transition: opacity 0.5s ease;
}

.order-label {
	margin-top: 10px;
}


.place-order {
	text-align: center;
}

.place-order-button {
	width: 80%;	
	margin-top: 10px;
	transition: background-color 0.3s ease, color 0.3s ease;
	padding-bottom: 2px;
}

.price-box-container {
	display: flex;
	justify-content: space-between; // or use 'space-around' for some space around the boxes
	align-items: center;
	margin: 10px;
}

.price-box {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	padding: 4px;
	width: 100%;
	height: 40px;
}

.price-box-buy {
	border-radius: 10px 0 0 10px;
	padding-left: 8px;
}

.price-box-sell {
	border-radius: 0 10px 10px 0;
	text-align: right;
	padding-right: 8px;
}
.right-nav{
	width: 	20rem;
}