// .Chart {
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    
// }

.Chart {
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "tnum" on, "lnum" on;
    font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    font-size: 11px;
}
#chartcontrols {
    // padding: 5px 5px 0 16px;
    max-width: 100%;
    height: auto;    
    // padding: 5px 45px 0 15px;
}

#chartdiv {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

.Chart-mask{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;     
    height: 3vh; 
    opacity: 1;
}