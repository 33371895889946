
.Symbol {
    width: 100%;    

    .Symbol-list {
        height: 62vh;
        overflow-y: overlay;
    }
    
    .search-container {
        margin-bottom: .6rem !important;
        position: relative;
        margin: 5px;

        .search-icon {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            display: none;
        }

        input {            
            padding: .5rem !important; 
            border-radius: 6px;          
        }
    }

    .Symbol-table {
        width: 100%;
    }

    .Symbol-thead{
        margin-bottom: .6rem !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span{
            min-width: 29.5% !important;
            font-weight: bold !important;
            &:nth-child(1){
                margin-left: -13% !important;
            }
            &:nth-child(2){
                margin-left: -13% !important;
            }
            &:nth-child(3){
                margin-left:-1% !important;
                // min-width: 39% !important;
           }
            &:nth-child(4){
               min-width: 24.5% !important;
           }
        }
    }

    .Symbol-th,
    .Symbol-td {
        font-weight: normal;
        padding: 2px;
        max-width: 50px;
        /* Prevent text from wrapping */
        white-space: nowrap;
        /* Hide overflow text */
        overflow: hidden;
        /* Show ellipsis (...) for overflow text */
        text-overflow: ellipsis;

        .afterDecimal {
            font-size: 0.80em;
        }
    }
    .Symbol-Name-style{
        text-align: end !important;
    }
    .Symbol-Name-Col {
        text-align: center !important;
    }

    .Symbol-th {
        text-align: left;
    }

}
.order-book-wrapper{
   overflow-x: hidden !important;
}