body {
    overflow-y: overlay;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
    }

    // &:focus {
    //     outline: none !important;
    // }    

    overflow-x: hidden;

    .card {
        margin: 1px !important;
    }

    .card-body {
        padding: 0px !important;
    }

    #divMain {
        margin: 5px;
    }

    .col-Left {
        padding-right: 0px;
        padding-left: 0px;
        border-right: 3.5px solid;
        overflow: hidden;
    }

    .col-Center {
        padding-right: 0px;
        padding-left: 0px;
        overflow: hidden;
    }

    .col-Right {
        padding-right: 0px;
        padding-left: 0px;
        border-left: 3.5px solid;
    }

    .col-Right2 {
        padding-right: 0px;
        padding-left: 0px;
        border-top: 2px solid;
    }

    .row-Bottom {
        border-top: 2px solid;
        padding-top: 15px;
        z-index: 2;
        // padding-right: 0px;
        // padding-left: 0px;
    }

    .card {
        // background-color: #525252;
        // color: #fff;
        margin-bottom: 2px;
    }

    .height-10 {
        height: 10vh;
    }

    .height-20 {
        height: 20vh;
    }

    .height-30 {
        height: 30vh;
    }

    .height-40 {
        height: 40vh;
    }

    .height-50 {
        height: 50vh;
    }

    .height-60 {
        height: 60vh;
    }

    .height-70 {
        height: 70vh;
    }

    .height-80 {
        height: 80vh;
    }

    .height-90 {
        height: 90vh;
    }

    .height-100 {
        height: 100vh;
    }
    .user-header-mobile{
        position: fixed;
        z-index: 999999;
        top: 0;
        left: 0;
        padding: 1rem !important;
        width: 100%;
        .user-container{
            margin-bottom: 0 !important;
        }
        .user-balance{
            display: none !important;
        }
        @media (min-width:700px) {
            display: none;
        }
    }
}